export const mapTexts = (texts) => {
  if (!texts) {
    return;
  }

  return {
    cardTokenizerForm: {
      buttonSubmitLabel: texts.submitButtonLabel,
      cardNumberLabel: texts.cardNumberLabel,
    },
  };
};
