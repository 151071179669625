import { h } from 'preact';
import { useMemo } from 'preact/hooks';

import Tokenizer from '~/containers/Tokenizer';
import mergeObjects from '~/utils/mergeObjects';

import { useTranslations } from '../../i18n/useTranslations';
import CardTokenizerForm from '../CardTokenizerForm';

const Stage = (props) => {
  const {
    extraData: { locale = process.env.DEFAULT_LANG } = {},
    publicToken,
    styles,
    texts = {},

    onTokenizationStart,
    onTokenizationSuccess,
    onTokenizationFail,
  } = props;

  const { i18n } = useTranslations(locale);

  const resultTexts = useMemo(() => {
    if (i18n) {
      return mergeObjects(i18n, texts);
    }

    return null;
  }, [i18n, texts]);

  if (!resultTexts) {
    return null;
  }

  return (
    <Tokenizer publicToken={publicToken} styles={styles}>
      <CardTokenizerForm
        onTokenizationStart={onTokenizationStart}
        onTokenizationSuccess={onTokenizationSuccess}
        onTokenizationFail={onTokenizationFail}
        texts={resultTexts}
      />
    </Tokenizer>
  );
};

export default Stage;
