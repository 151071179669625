export default class CSSDeclaration {
  constructor(selector, rules) {
    this.selector = selector;
    this.rules = rules;
  }

  toString() {
    return Object.entries(this.rules).reduce(
      (result, [propertyName, propertyValue], index, rulesArray) => {
        const additionStart = index === 0 ? ' {' : '';
        const additionEnd = index === rulesArray.length - 1 ? '}' : '';

        return `${result}${additionStart}${propertyName}: ${propertyValue};${additionEnd}`;
      },
      this.selector,
    );
  }
}
