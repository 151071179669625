import CSSDeclaration from '../CSSDeclaration';

const { PREFIX } = process.env;

const mapStyleToCSSSelector = (name) => {
  switch (name) {
    case 'inputContainer':
      return `.${PREFIX}-InputBase__container`;
    case 'inputField':
      return `.${PREFIX}-InputBase__field`;
    case 'inputFieldIsFocused':
      return `.${PREFIX}-InputBase__isFocused .${PREFIX}-InputBase__field`;
    case 'inputFieldIsInvalid':
      return `.${PREFIX}-InputBase__isInvalid .${PREFIX}-InputBase__field`;
    case 'inputFieldPlaceholder':
      return `.${PREFIX}-InputBase__field::placeholder`;
    default:
      return name;
  }
};

const stylesBySelectors = (styles) =>
  Object.entries(styles).reduce(
    (result, [elem, rules]) =>
      rules
        ? {
            ...result,
            [mapStyleToCSSSelector(elem)]: rules,
          }
        : { ...result },
    {},
  );

const addPrefixToSelector = (selector, prefix) => {
  if (prefix && selector.includes('::')) {
    const [selectorElement, pseudoElement] = selector.split('::');

    return `${selectorElement}::${prefix}${pseudoElement}`;
  }

  return selector;
};

export const addStyles = (styles) => {
  const style = stylesBySelectors(styles);

  if (Object.keys(style).length === 0) {
    return;
  }

  const customStyle = document.createElement('style');

  document.head.appendChild(customStyle);
  const { sheet } = customStyle;

  Object.entries(style).forEach(([selector, rules]) => {
    try {
      const decl = new CSSDeclaration(selector, rules);

      sheet.insertRule(decl.toString(), sheet.cssRules.length);
    } catch (e) {
      const decl = new CSSDeclaration(
        addPrefixToSelector(selector, '-ms-input-'),
        rules,
      );

      sheet.insertRule(decl.toString(), sheet.cssRules.length);
    }
  });
};
