import { h } from 'preact';
import { memo } from 'preact/compat';
import { useCallback, useState } from 'preact/hooks';
import { Form } from 'react-final-form';

import { Button } from '~/components/Button';
import { Field as FormField } from '~/components/Field';
import { useTokenizer } from '~/containers/Tokenizer';
import TokenizerCardNumber from '~/containers/Tokenizer/TokenizerCardNumber';
import mergeObjects from '~/utils/mergeObjects';

import * as styles from './styles.css';

const FIELD_NAMES = {
  cardNumber: 'cardNumber',
};

const CardTokenizerForm = (props) => {
  const {
    texts = {},

    onTokenizationStart,
    onTokenizationSuccess,
    onTokenizationFail,
  } = props;

  const mapErrors = useCallback(
    (code) => {
      return (
        mergeObjects(
          {
            INVALID_CARD_NUMBER: texts.validationErrors.invalidCardNumber,
            IS_REQUIRED: texts.validationErrors.isRequired,
          },
          texts.validationErrors,
        )[code] || code
      );
    },
    [texts],
  );

  const tokenizer = useTokenizer();
  const [errors, setErrors] = useState({
    [FIELD_NAMES.cardNumber]: null,
  });

  const handleBind = useCallback(async () => {
    onTokenizationStart();

    try {
      const token = await tokenizer.tokenize(FIELD_NAMES.cardNumber);

      onTokenizationSuccess(token);
    } catch (error) {
      onTokenizationFail(error);
    }
  }, [
    tokenizer,
    onTokenizationStart,
    onTokenizationSuccess,
    onTokenizationFail,
  ]);

  const handleInvalid = useCallback(
    (code, name) =>
      setErrors((restErrors) => ({
        ...restErrors,
        [name]: mapErrors(code),
      })),
    [mapErrors],
  );

  const handleValid = useCallback(
    (name) =>
      setErrors((restErrors) => ({
        ...restErrors,
        [name]: null,
      })),
    [],
  );

  return (
    <Form onSubmit={handleBind}>
      {({ submitting, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.col}>
                <FormField
                  component={TokenizerCardNumber}
                  error={errors.cardNumber}
                  label={texts.cardTokenizerForm.cardNumberLabel}
                  name={FIELD_NAMES.cardNumber}
                  onInvalid={handleInvalid}
                  onSubmit={handleSubmit}
                  onValid={handleValid}
                />
              </div>
            </div>
            <div className={styles.row}>
              <Button disabled={submitting} type="submit">
                {texts.cardTokenizerForm.buttonSubmitLabel}
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

export default memo(CardTokenizerForm);
