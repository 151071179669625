import { h } from 'preact';
import { memo } from 'preact/compat';
import { useEffect } from 'preact/hooks';

import MainContainer from '~/containers/MainContainer';
import WidgetBase, { TYPES as WIDGET_TYPES } from '~/containers/WidgetBase';

import Stage from './components/Stage';
import { addStyles } from './utils/addStyles';
import { mapTexts } from './utils/mapTexts';

const CardTokenizerContainer = ({
  styles,
  publicToken,
  texts,

  onReady,
  onTokenizationStart,
  onTokenizationSuccess,
  onTokenizationFail,
}) => {
  const { cardTokenizer } = styles;

  useEffect(() => {
    addStyles(cardTokenizer);
    onReady();
  }, [onReady, cardTokenizer]);

  return (
    <WidgetBase
      publicToken={publicToken}
      render={({ extraData }) => {
        return (
          <MainContainer locale={extraData?.locale}>
            <Stage
              extraData={extraData}
              publicToken={publicToken}
              onTokenizationStart={onTokenizationStart}
              onTokenizationSuccess={onTokenizationSuccess}
              onTokenizationFail={onTokenizationFail}
              styles={styles}
              texts={mapTexts(texts)}
            />
          </MainContainer>
        );
      }}
      type={WIDGET_TYPES.tokenize}
    />
  );
};

export default memo(CardTokenizerContainer);
