import { h, render } from 'preact';

import CardTokenizerContainer from './containers/CardTokenizerContainer';
import mergeObjects from './utils/mergeObjects';

class CardTokenizer {
  constructor(publicToken, options = {}) {
    this.publicToken = publicToken;
    this.options = mergeObjects(options, {
      container: document.getElementById(
        `${process.env.PREFIX}-card-tokenizer`,
      ),
    });
  }

  onReady = () => null;

  onTokenizationStart = () => null;
  onTokenizationFail = () => null;
  onTokenizationSuccess = () => null;

  isRendered = false;

  render(options = {}) {
    const {
      container,
      texts,
      styles = {
        cardTokenizer: {
          inputContainer: undefined,
          inputField: undefined,
          inputFieldIsFocused: undefined,
          inputFieldIsInvalid: undefined,
          inputFieldPlaceholder: undefined,
        },
      },
    } = mergeObjects(this.options, options);

    container.innerHTML = '';

    render(
      <CardTokenizerContainer
        styles={styles}
        publicToken={this.publicToken}
        onReady={this.onReady}
        onTokenizationStart={this.onTokenizationStart}
        onTokenizationFail={this.onTokenizationFail}
        onTokenizationSuccess={this.onTokenizationSuccess}
        texts={texts}
      />,
      container,
    );

    this.isRendered = true;
  }
}

window[`${process.env.PREFIX_CAPITALIZED}CardTokenizer`] = CardTokenizer;
